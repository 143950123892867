import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";
import { useQuery } from "../../hooks/useQuery";
import { useDailyActivityRewards } from ".";
import { useScreenType } from "../../hooks/useScreenType";
import {
  DailyActivityRewardsQuery,
  DailyActivityRewardsQueryVariables,
} from "./graphql/DailyActivityRewardsQuery.generated";
import { loader } from "graphql.macro";
import styles from "./DailyActivityRewardPopup.module.scss";
import { Currency } from "../../graphql/generated";
import { DailyActivityRewardItemFragmentFragment } from "../../graphql/DailyActivityRewardItemFragment.generated";
import { Amount } from "../Amount";
import completedRewardImg from "./assets/completed_reward.png";
import currentRewardImg from "./assets/current_reward.png";
import futureRewardImg from "./assets/future_reward.png";

type rewardTimeType = "COMPLETED" | "CURRENT" | "FUTURE";

const DAILY_ACTIVITY_REWARDS_QUERY = loader(
  "./graphql/DailyActivityRewardsQuery.graphql"
);

const PopupContainer = ({ children }: { children?: React.ReactNode }) => {
  const screenType = useScreenType();

  return (
    <div className={`absolute top-0 left-0 right-0 bottom-0 z-50`}>
      {screenType === "DESKTOP" ? (
        <AbsoluteCenter>{children}</AbsoluteCenter>
      ) : (
        <div className="flex justify-center items-center h-screen">
          {children}
        </div>
      )}
    </div>
  );
};

const Reward = ({
  currency,
  amount,
  imageSize,
}: {
  currency: Currency;
  amount: number;
  imageSize?: number;
}) => {
  return (
    <button className={styles.rewardItem} key={currency}>
      <Amount
        value={amount}
        currency={currency}
        fontSize={16}
        imageSize={imageSize}
      />
    </button>
  );
};

const RewardConfigItem = ({
  item,
  idx,
  type,
}: {
  item: DailyActivityRewardItemFragmentFragment;
  idx: number;
  type?: rewardTimeType;
}) => {
  const { t } = useTranslation();
  return (
    <div key={item.dayNumber + idx} className={styles.configContainer}>
      <div
        className={`${styles.configContainerTitle} ${
          type === "COMPLETED"
            ? styles.completedConfigurationTitle
            : type === "FUTURE"
            ? styles.futureConfigurationTitle
            : styles.currentConfigurationTitle
        }`}
      >
        <h2 className="text-center w-full text-xxl font-bold">
          {t("daily_activity_rewards_popup.day_number", {
            number: item.dayNumber,
          })}
        </h2>
      </div>
      {type === "COMPLETED" ? (
        <div className={styles.completedReward}>
          <img src={completedRewardImg} />
        </div>
      ) : (
        <div
          className={`${styles.rewardConfigurationContainer} ${
            type === "FUTURE"
              ? styles.futureRewardBorder
              : styles.currentRewardBorder
          }`}
        >
          <img
            src={type === "FUTURE" ? futureRewardImg : currentRewardImg}
            alt="completed_reward_img"
          />
          <div
            className={`${styles.configurationRewardsContainer} flex flex-col gap-1`}
          >
            {item.config.blueChips > 0 && (
              <Reward
                currency={Currency.BlueChips}
                amount={item.config.blueChips}
                imageSize={24}
              />
            )}
            {item.config.goldChips > 0 && (
              <Reward
                currency={Currency.GoldChips}
                amount={item.config.goldChips}
                imageSize={24}
              />
            )}
            {item.config.rewardPoints > 0 && (
              <Reward
                currency={Currency.RewardPoints}
                amount={item.config.rewardPoints}
                imageSize={24}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const CloseButton = ({ closeHandler }: { closeHandler: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-6 flex justify-center">
      <ButtonWithSound
        className={`${styles.closeButton} btn btn-blue-500 btn-lg`}
        onClick={closeHandler}
      >
        {t("close")}
      </ButtonWithSound>
    </div>
  );
};

const RewardConfigs = ({
  data,
  getRewardItemType,
}: {
  data: DailyActivityRewardsQuery | undefined;
  getRewardItemType: (
    item: NonNullable<DailyActivityRewardsQuery["dailyActivityRewards"]>[0],
    index: number
  ) => rewardTimeType;
}) => {
  return (
    <div className="mt-4">
      <div
        className={`${styles.rewardConfigsContainer} ${
          data?.dailyActivityRewards && data?.dailyActivityRewards?.length > 6
            ? styles.moreThanOneLineRewards
            : styles.oneLineRewars
        }`}
      >
        {data?.dailyActivityRewards?.map((item, idx) => {
          const type = getRewardItemType(item, idx);
          return <RewardConfigItem item={item} idx={idx} type={type} />;
        })}
      </div>
    </div>
  );
};

const PaidRewards = ({
  totalPaid,
}: {
  totalPaid: {
    blueChips: number;
    goldChips: number;
    rewardPoints: number;
  };
}) => {
  return (
    <div className={styles.paidRewards}>
      <Reward currency={Currency.BlueChips} amount={totalPaid.blueChips} />
      <Reward currency={Currency.GoldChips} amount={totalPaid.goldChips} />
      <Reward
        currency={Currency.RewardPoints}
        amount={totalPaid.rewardPoints}
      />
    </div>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerTitle}>
      <h1>{t("daily_activity_rewards_popup.title")}</h1>
    </div>
  );
};

const Header = ({
  totalPaid,
}: {
  totalPaid: {
    blueChips: number;
    goldChips: number;
    rewardPoints: number;
  } | null;
}) => {
  return (
    <div className={styles.header}>
      <Title />
      {totalPaid && <PaidRewards totalPaid={totalPaid} />}
    </div>
  );
};

const Popup = () => {
  const { closePopup } = useDailyActivityRewards();
  const [{ fetching, data }] = useQuery<
    DailyActivityRewardsQuery,
    DailyActivityRewardsQueryVariables
  >({
    query: DAILY_ACTIVITY_REWARDS_QUERY,
  });

  React.useEffect(() => {
    if (!fetching && data?.dailyActivityRewards?.length === 0) {
      closePopup();
    }
  }, [fetching, data]);

  const totalPaid = React.useMemo(() => {
    if (data?.dailyActivityRewards) {
      const totals = data.dailyActivityRewards.reduce(
        (acc, item) => {
          acc.blueChips += item.paid?.blueChips || 0;
          acc.goldChips += item.paid?.goldChips || 0;
          acc.rewardPoints += item.paid?.rewardPoints || 0;
          return acc;
        },
        { blueChips: 0, goldChips: 0, rewardPoints: 0 }
      );
      if (
        totals.blueChips !== 0 ||
        totals.goldChips !== 0 ||
        totals.rewardPoints !== 0
      ) {
        return totals;
      }
    }
    return null;
  }, [data]);

  const getRewardItemType = React.useCallback(
    (
      item: NonNullable<DailyActivityRewardsQuery["dailyActivityRewards"]>[0],
      index: number
    ) => {
      switch (true) {
        case !!item.paid:
          return "COMPLETED";
        case index === 0:
          return "CURRENT";
        case !!data?.dailyActivityRewards?.[index - 1]?.paid:
          return "CURRENT";
        default:
          return "FUTURE";
      }
    },
    [data]
  );

  if (fetching) {
    return <></>;
  }

  return (
    <PopupContainer>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <Header totalPaid={totalPaid} />
          <RewardConfigs data={data} getRewardItemType={getRewardItemType} />
        </div>
        <CloseButton closeHandler={closePopup} />
      </div>
    </PopupContainer>
  );
};

export function DailyActivityRewardPopup() {
  const { isPopupOpened } = useDailyActivityRewards();

  return <>{isPopupOpened && <Popup />}</>;
}
