import * as React from "react";
import styles from "./Amount.module.css";
import { CurrencyImage } from "../CurrencyImage";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { Currency } from "../../graphql/generated";
import classNames from "classnames";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";

const BalanceValue = ({
  value,
  fontSize,
}: {
  value: number;
  fontSize?: number;
}) => {
  const numberFormatter = useNumberFormatter();
  const animatedValue = useAnimatedCounter(value, { duration: 3 });
  return (
    <span
      className={styles.balanceText}
      style={fontSize ? { fontSize: `${fontSize}px` } : undefined}
    >
      {numberFormatter.format(Math.floor(animatedValue))}
    </span>
  );
};

export const Amount = React.memo<{
  value: number;
  currency: Currency;
  fontSize?: number;
  imageSize?: number;
}>(({ value, currency, fontSize, imageSize }) => {
  return (
    <div className="relative">
      <CurrencyImage
        currency={currency}
        width={imageSize ?? 36}
        height={imageSize ?? 36}
        className="absolute"
      />
      <div
        className={classNames(
          "leading-none text-center, flex items-center justify-center",
          styles.balance
        )}
        style={imageSize ? { height: `${imageSize}px` } : undefined}
      >
        <BalanceValue value={value} fontSize={fontSize} />
      </div>
    </div>
  );
});
